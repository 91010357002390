// font-size Mixin
// compiles to font-size mobile + font-size desktop on small-plus devices
// ex. h2 { @include font-size(h2);}
@mixin font-size($elem) {
  $q: map-get($font-sizes, $elem);
  $mob: map-get($q, "mobile");
  $desk: map-get($q, "desktop");
  font-size: $mob;
  @include respond-to("small-up") {
    font-size: $desk;
  }
}

// Grid Mixin
// arguments are : columns number, gutter, min-breakpoint
// ex. .ingrid { @include grid(4, 1rem, 640px); }
@mixin grid($number:1, $gutter:0, $breakpoint:0) {
  @media (min-width: $breakpoint) {
    display: grid;
    grid-template-columns: repeat($number, 1fr);
    grid-gap: $gutter;
  }
}

// Additionnal "utility" breakpoints aliases
// ex. @include respond-to("medium-up") {...}
$bp-aliases: (
  'tiny'   : (max-width: #{$tiny - 1}),
  'small'  : (max-width: #{$small - 1}),
  'medium' : (max-width: #{$medium - 1}),
  'large' : (max-width: #{$large - 1}),
  'extra-large'  : (max-width: #{$extra-large - 1}),
  'tiny-up'   : (min-width: #{$tiny}),
  'small-up'  : (min-width: #{$small}),
  'medium-up' : (min-width: #{$medium}),
  'large-up' : (min-width: #{$large}),
  'extra-large-up' : (min-width: #{$extra-large}),
  'retina' : (min-resolution: 2dppx)
);

// Source : https://www.sitepoint.com/managing-responsive-breakpoints-sass/
@mixin respond-to($name) {
  // If the key exists in the map
  @if map-has-key($bp-aliases, $name) {
    // Prints a media query based on the value
    @media #{inspect(map-get($bp-aliases, $name))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}
