/* ----------------------------- */
/* ==skip links                  */
/* ----------------------------- */
/* see https://www.alsacreations.com/article/lire/572-Les-liens-d-evitement.html */

/* styling skip links */
.skip-links {
  position: absolute;

  & a {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0.5em;
    background: black;
    color: white;
    text-decoration: none;

    &:focus {
      position: static;
      overflow: visible;
      clip: auto;
    }
  }
}
