
  // Page

    .page {
      padding-top: 10rem;

      h1 {
        margin-bottom: 3rem;
        text-align: center;
        font: 600 4rem $font-stack-title;
        line-height: 1.4;
      }

      h2 {
        margin-bottom: 1.75rem;
        font-family: $font-stack-subtitle;
        line-height: 1.3;
      }

      h3 {
        margin-bottom: 1.75rem;
        font-weight: 300;
      }

      img {
        margin: 2rem 0;
      }

      blockquote {
        margin: 2.5rem 0;
      }
    }
