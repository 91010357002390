
	// Styles Communs

		html {
			display: flex;
			flex-direction: column;
		}

		body {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
		}

		main {
			flex: 1 1 auto;
		}

		.is-fixed {
			width: 100%;
			height: 100%;
			position: fixed;
			overflow: hidden;
		}

    p {
      color: rgba($black, .6);
      line-height: 1.7;

      a, .link {
        cursor: pointer;
        color: $anaf-violet;
        text-decoration: underline;

        &:hover {
          color: $anaf-violet-clair;
        }
      }
    }

    b, strong {
      font-weight: 600;
    }

  // Conteneurs

      %container {
        width: 100%;
        position: relative;
        margin: 0 auto;
        padding: 1rem 1.5rem;
      }

      .container-tiny {
        max-width: 70rem;
        @extend %container;
      }

      .container-small {
        max-width: 90rem;
        @extend %container;
      }

      .container {
        max-width: 110rem;
        @extend %container;
      }

      .container-large {
        max-width: 130rem;
        @extend %container;
      }

  // Titres

    h1 {
    }

    h2 {
    }

    h3, .h3 {
      font-size: 2.2rem;
    }

  // Classes Utilitaires

    .align-items-center {
      align-items: center;
    }

    .justify-content-center {
      justify-content: center;
    }

  // Boutons

  // Iframe Responsive

    .iframe-wrapper {
      position: relative;
    }

    .iframe-sub-wrapper {
      position: relative;
      height: 0;
      padding: 0 0 56.25%; // Ratio 16/9

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
