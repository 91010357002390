
  // Fil d'ariane (BreadCrumbs)

    .breadcrumbs {
      margin-bottom: 2rem;
      text-align: center;
      font-size: 1.2rem;

      a {
        &:not(:last-child) {
          margin-right: .5rem;
        }

        &:not(:first-child) {
          margin-left: .5rem;
        }
      }
    }
