
  // Entête

    .blog__entete {
      padding-top: 8.5rem;
      position: relative;
      background: {
        position: bottom center;
        repeat: no-repeat;
        size: contain;
      }

      @include respond-to("medium-up") {
        background-size: cover;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        z-index: -1;
        background-image: linear-gradient(to right,#c0c1bb 10%,#ddddd3 50%,#e6e3d2 70%);
      }

      &:after {
        display: block;
        width: 100%;
        padding-top: 110%;
        content: '';

        @include respond-to("tiny-up") {
          padding-top: 60%;
        }

        @include respond-to("medium-up") {
          padding-top: 40%;
        }

        @include respond-to("extra-large-up") {
          padding-top: 30%;
        }
      }
    }

    .blog__entete-sub {
      position: absolute;
      top: 8.5rem;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;

      h1 {
        width: 100%;
        max-width: 50rem;
        font: 600 1.8rem $font-stack-title;
        text-transform: uppercase;
        letter-spacing: .2rem;
        line-height: 1.4;

        @include respond-to("medium-up") {
          font-size: 2.5rem;
        }

        span {
          font-size: 2.5rem;
          display: inline-block;

          @include respond-to("medium-up") {
            font-size: 4rem;
          }
        }
      }

      p {
        width: 100%;
        max-width: 55rem;
        font-size: 1.6rem;
        font-weight: 300;

        @include respond-to("medium-up") {
          font-size: 2rem;
        }
      }
    }

  // Liste

    .blog__list {
      padding: 5rem 0 2rem;

      h2 {
        margin-bottom: 2.5rem;
        font: 600 2.5rem $font-stack-title;
        text-transform: uppercase;
        letter-spacing: .2rem;
        line-height: 1.4;
      }
    }

    .blog__list-vertical {
      article {
        max-width: 55rem;
        display: flex;

        &:not(:last-child) {
          margin-bottom: 2.5rem;
        }

        .blog__item-image {
          width: 40%;
        }

        .blog__item-content {
          width: 60%;
        }

        .blog__item-title {
          padding: 0;

          @include respond-to("small-up") {
            padding: 2rem 0;
          }
        }
      }
    }

  // Item

    .blog__item {
      position: relative;
      margin-bottom: 3.5rem;

      @include respond-to("small") {
        margin-bottom: 3rem;
      }
    }

    .blog__item-content {
      max-width: 55rem;
      padding: 0 2rem;
    }

    .blog__item-image {
      img {
        box-shadow: 0 2rem 3.5rem -2rem rgba($black, .5);
      }
    }

    .blog__item-title {
      padding: 2rem 0 0;

      h3 {
        font-family: $font-stack-subtitle;
        line-height: 1.2;
      }
    }

    .blog__item-text {
      margin-bottom: .75rem;
      font-size: 1.4rem;
      color: rgba($black, .8);
    }

    .blog__item-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .blog__item-date {
      justify-content: space-between;
      font-size: 1.3rem;
      color: $anaf-violet-clair;

      span {
        &:nth-child(2) {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

  // Post

    .blog__post-infos {
      text-align: center;
      font-size: 1.3rem;
    }

    .blog__post-date {
      margin: 0;
    }

    .blog__post-site {
      a {
        color: $anaf-violet;
      }
    }

  // Liste (Siblings)

    .blog__posts-siblings {
      h2 {
        font-family: $font-stack-subtitle;
        line-height: 1.3;
      }
    }

  // More (Accueil)

    .blog__more {
      h2 {
        margin-bottom: 2.5rem;
        font: 600 2.5rem $font-stack-title;
        text-transform: uppercase;
        letter-spacing: .2rem;
        line-height: 1.4;
      }

      blockquote {
        max-width: 70rem;
      }
    }

    .blog__more-left {
      width: 100%;
      margin-bottom: 4rem;
      text-align: center;

      @include respond-to("medium-up") {
        width: 30%;
        margin-bottom: 0;
      }
    }

    .blog__more-right {
      @include respond-to("medium-up") {
        width: 70%;
        padding-left: 3rem;
      }
    }
