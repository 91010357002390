
	// Fonts (Polices de caractère)

		// Marcellus - Regular (Exemple)

			@font-face {
		    font-family: 'Marcellus';
		    src: url('../fonts/marcellus/marcellus-regular.woff2') format('woff2'),
		         url('../fonts/marcellus/marcellus-regular.woff') format('woff'),
		         url('../fonts/marcellus/marcellus-regular.ttf') format('truetype'),
				     url('../font/marcellus/marcellus-regular.svg#Marcellus') format('svg');
		    font-weight: 400;
		    font-style: normal;
			}

      @font-face {
        font-family: 'Assistant';
        src: url('../fonts/assistant/assistant-bold.woff2') format('woff2'),
             url('../fonts/assistant/assistant-bold.woff') format('woff'),
             url('../fonts/assistant/assistant-bold.ttf') format('truetype'),
             url('../font/assistant/assistant-bold.svg#Assistant') format('svg');
        font-weight: 600;
        font-style: normal;
      }

