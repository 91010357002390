
  // Plan du site

    .page__plan-du-site {
      ul {
        margin-bottom: 1rem;

        li {
          margin-bottom: 1rem;

          &:first-child {
            margin-top: 1rem;
          }
        }
      }
    }
