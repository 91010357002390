
	// Pied de page

  	footer {
      p {
        margin: 0;
        text-align: center;
        font-size: 1.2rem;
      }
  	}
