
  // Navigation

  .is-mobile-nav-open {
    .header {
      background-image: linear-gradient(to right,#c0c1bb 10%,#ddddd3 50%,#e6e3d2 70%);
    }
  }

    .header {
      position: fixed;
      left: 0;
      right: 0;
      z-index: 999;
      border-bottom: .1rem solid rgba($black, .075);

      &:not(.home) {
        background-image: linear-gradient(to right,#c0c1bb 10%,#ddddd3 50%,#e6e3d2 70%);
      }

      &.is-scrolled {
        @extend %degrade;

        .header__navigation-logo {
          padding: 0;

          img {
            width: 15rem;
          }
        }
      }
    }

    .header__navigation--group {
      @include respond-to("small") {
        width: 100%;
      }
    }

    .header__navigation-logo {
      padding: 1rem 0;
      transition: .3s padding ease-out;

      img {
        width: 15rem;
        transition: .3s width ease-out;

        @include respond-to("small-up") {
          width: 20rem;
        }
      }
    }

    .header__navigation {
      display: none;
      margin-bottom: 0;
      margin-left: auto;
      padding: 0;
      list-style: none;

      @include respond-to("small") {
        width: 100%;
        padding: 1rem;
      }

      @include respond-to("small-up") {
        display: flex;
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;

        a, span {
          display: inline-block;
          padding: 0 1rem;
          cursor: pointer;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 1.4rem;
          letter-spacing: .1rem;
          font-weight: 600;
          transition: .3s color ease;

          &:hover {
            color: $anaf-violet;
          }

          @include respond-to("small") {
            width: 100%;
            padding: 1rem;
            text-align: center;
          }
        }
      }
    }

    .hamburger {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;

      &:focus {
        outline: none;
      }

      @include respond-to("small-up") {
        display: none;
      }

      .hamburger-label {
        margin-right: 1rem;
        text-transform: uppercase;
        font-weight: 400;
        color: $black;
      }
    }
