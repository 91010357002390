
	// Reset

		input[type=number] {
			-moz-appearance: textfield;
		}

		input[type=number]::-webkit-inner-spin-button,
		input[type=number]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		input:-webkit-autofill { // Background Yellow on Chrome
      -webkit-box-shadow: 0 0 0 3rem white inset;
      color: $black;
		}

		::-ms-clear {
	    display: none;
	  }

	  ::-ms-reveal {
	    display: none;
	  }

	  input[type=text], textarea {
	    -webkit-appearance: none;
	   	-moz-appearance: none;
	    appearance: none;
	  }

	// Placeholder

		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: rgab($black, .7);
		}

		::-moz-placeholder { /* Firefox 19+ */
			color: rgab($black, .7);
		}

		:-ms-input-placeholder { /* IE 10+ */
			color: rgab($black, .7);
		}

		:-moz-placeholder { /* Firefox 18- */
			color: rgab($black, .7);
		}

  // Inputs

    textarea,
    input[type="text"],
    input[type="tel"],
    input[type="url"],
    input[type="file"],
    input[type="date"],
    input[type="email"],
    input[type="password"] {
      display: inline-block;
      width: 100%;
      padding: 1rem;
      font-size: 1.4rem;
      color: $black;
      line-height: inherit;
      vertical-align: middle;
      box-shadow: 0 0 0 .1rem rgba($black, .3) inset;
      border-radius: .3rem;
      // background: rgba($black, .1);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      transition: box-shadow .2s ease-in-out;

      &:hover,
      &:focus,
      &:active {
        outline: none;
        box-shadow: 0 0 0 .1rem rgba($black, .8) inset;
      }

      &.error {
        box-shadow: 0 0 0 0.1rem $red-500 inset;
      }
    }

  // Label

    label {
      margin-bottom: .7rem;
      color: $black;
      font-weight: 600;
      font-size: 1.4rem;

      &.error {
        margin-top: .5rem;
        color: $red-500;
        font-weight: 400;
        font-size: 1.3rem;
      }
    }

  // Textarea

    textarea {
      height: 15rem;
      overflow: hidden;
      resize: none;
    }

  // Contact

    .form-field {
      margin-bottom: 1.5rem;
    }
