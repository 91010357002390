/* ----------------------------- */
/* ==Checkbox, radio, switch     */
/* ----------------------------- */
/* use .checkbox class on input type=checkbox */
/* recommanded HTML : <input type="checkbox" class="checkbox" id="c1"><label for="c1">click here</label> */
/* use .radio class on input type=radio */
/* recommanded HTML : <input type="radio" class="radio" name="radio" id="r1"><label for="r1">Click here</label> */
/* use .switch class on input type=checkbox */
// <input type="checkbox" class="switch" id="switch"><label for="switch" class="label">slide to unlock</label>

// common styles
.checkbox {
  border-radius: $checkbox-border-radius;
}

.switch {
  border-radius: $switch-border-radius;
}

.radio {
  border-radius: 50%;
}

.switch,
.checkbox,
.radio {
  appearance: none;
  vertical-align: text-bottom;
  outline: 0;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px $checkbox-color;
  background: #fff;

  ~ label {
    cursor: pointer;
  }

  &::-ms-check {
    display: none; // unstyle IE checkboxes
  }
}

// switch styling
.switch {
  width: $switch-size *2;
  height: $switch-size;
  line-height: $switch-size;
  font-size: 60%;
  box-shadow: inset -#{$switch-size} 0 0 $switch-color, inset 0 0 0 1px $switch-color;
  transition: box-shadow .15s;
  &::before, &::after {
    font-weight: bold;
    color: #fff;
  }
  &::before {
    content: "✕";
    float: right;
    margin-right: $switch-size /3;
    }
  &:checked {
    box-shadow: inset #{$switch-size} 0 0 $color-success, inset 0 0 0 1px $color-success;
    &::before {
      content: "✓";
      float: left;
      margin-left: $switch-size /3;
    }
  }
}


// checkbox styling
.checkbox {
  width: $checkbox-size;
  height: $checkbox-size;
  transition: background-color .15s;
  &:checked {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
    background-size: 60% 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $checkbox-color;
  }
}

// radio styling
.radio {
  width: $checkbox-size;
  height: $checkbox-size;
  transition: background-color .15s;
  &:checked {
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20version%3D%221.1%22%20width%3D%2240%22%20height%3D%2240%22%0AviewBox%3D%220%200%2080%2080%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Ccircle%20cx%3D%2240%22%20cy%3D%2240%22%20r%3D%2224%22%20style%3D%22fill%3A%23ffffff%22/%3E%3C/svg%3E);
    background-size: 80% 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $checkbox-color;
  }
}
